import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import HeroContainer from '../../components/section-hero/hero-container'

const Hero04 = (props) => {
    const { data } = props
    const { edges: posts } = data.allMarkdownRemark
  return (
    <>    

        {posts &&
            posts.map(({ node: post }) => (
              <>
                <HeroContainer 
                  id={post.frontmatter.sectionCode} 
                  overlay={post.frontmatter.overlay}
                  imgDesktop={post.frontmatter.imageDesktop}
                  imgMobile={post.frontmatter.imageMobile}
                  caption={post.frontmatter.caption}
                  linksTo={post.frontmatter.linksTo}
                />
            </>
        ))}
    
    </>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query Hero04Query {

        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "hero-04" } } }
        ) {
        edges {
            node {
                id
                frontmatter {
                  title
                  templateKey
                  sectionCode
                  linksTo
                  overlay
                  imageDesktop
                  imageMobile
                  caption
                }
            }
        }
        }
    }
    `}
    render={(data) => <Hero04 data={data} />}
  />
)
