import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WhyEveryoneContentTemplate from "../templates/why-everyone/why-everyone-content-template"
import Hero04 from '../templates/home/hero-04-template'
import {Helmet} from 'react-helmet'


const WhyEveryOnePage = () => (
  <>
    <Layout>
      <Helmet>
        <body className="inner-page" data-active-page="page-why-everyone" />
      </Helmet>
      <SEO title="Why EveryOne" />
      {/* <HeroImgSubPage /> */}
      <Hero04 />
      <section className="content-container">
        <WhyEveryoneContentTemplate />
      </section>
    </Layout>
  </>
)

export default WhyEveryOnePage
